import { useForm } from 'react-hook-form'
import { ScrollArea } from '../ui/scroll-area'
import { Separator } from '../ui/separator'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from 'sonner'
import { useEffect, useState } from 'react'
import {
  postDocuments,
} from '@/services/api-client'
import CommentForm from './CommentForm'
import EmptyData from '../empty-data'
import ProfilLoader from '../loader/profil-loader'
import { TicketType } from '@/types/ITicket'
import CommentItemIssue from './CommentItemIssue'
import clsx from 'clsx'
import { addIssueComments } from '@/services/comment.service'

const formSchema = z.object({
  content: z.string().min(1, { message: '' }),
})

interface Props {
  id: string
  commentsProps: TicketType['fields']['comment']['comments']
  isDialog?: boolean
}
export default function CommentsIssue({
  id,
  commentsProps,
  isDialog,
}: Readonly<Props>) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      content: '',
    },
  })

  const [isLoadComments, setIsLoadComments] = useState(true)
  const [isLoadSubmitComments, setIsLoadSubmitComments] = useState(false)
  const [commentsList, setCommentsList] = useState<
    TicketType['fields']['comment']['comments']
  >([])
  const [currentPucture, setcurrentPucture] = useState<any>()

  useEffect(() => {
    setCommentsList(commentsProps ?? [])
    setIsLoadComments(false)
  }, [commentsProps])

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      setIsLoadSubmitComments(true)
      const { data }: { data: any } = await addIssueComments({
        issueID: id,
        content: values.content,
      })
      let incomingPicResponse: any = null
      if (currentPucture) {
        const { data: incomingPic } = await postDocuments(id, {
          file: currentPucture,
        })
        incomingPicResponse = incomingPic.file
      }
      setCommentsList((prev) => [
        ...prev,
        { ...data, newBody: data.body, file: incomingPicResponse || '' },
      ])
    } catch (error) {
      throw new Error('Unexpected error occured')
    } finally {
      setIsLoadSubmitComments(false)
      form.reset()
    }
  }
  function onInvalid() {
    toast('Un commentaire ne doit pas être vide!')
  }


  return (
    <div className="w-full">
      {isDialog ? (
        ''
      ) : (
        <p className="pb-5 font-medium text-base text-primary-dark">
          Commentaires
          <Separator className={clsx(isDialog ? '' : 'absolute left-0')} />
        </p>
      )}
      {/* COMMENT ITEM */}
      <ScrollArea className={clsx(isDialog ? '' : 'h-[76.5vh] pb-[144px]')}>
        <div className="h-full pr-2">
          {isLoadComments ? (
            <div className="py-4 flex flex-col gap-y-4">
              <ProfilLoader />
              <ProfilLoader />
              <ProfilLoader />
              <ProfilLoader />
            </div>
          ) : (
            <>
              {commentsList.length ? (
                commentsList.map((comments, index) => (
                  <CommentItemIssue
                    key={comments.id + index}
                    comments={comments}
                  />
                ))
              ) : (
                <div
                  className={clsx(
                    isDialog ? 'h-56 flex justify-center items-center' : ''
                  )}
                >
                  <EmptyData msg="Aucun commentaire disponible" />
                </div>
              )}
            </>
          )}
        </div>
      </ScrollArea>
      <CommentForm
        isDialog={isDialog}
        form={form}
        onSubmit={onSubmit}
        onInvalid={onInvalid}
        isLoadSubmit={isLoadSubmitComments}
        setcurrentPucture={setcurrentPucture}
      />
    </div>
  )
}
