import { SprintNameAtom } from '@/atom/ticket'
import FilterBar from '@/components/sprint-in-progress/FilterBar'
import SprintInProgressHeader from '@/components/sprint-in-progress/Header'
import SprintListing from '@/components/sprint-in-progress/SprintListing'
import {
  getTicketInProgress,
  getTicketDone,
  getTicketTesting,
  getTicketToDo,
} from '@/services/ticket.service'
import { CustomField10020, TicketType } from '@/types/ITicket'
import { useSetAtom } from 'jotai'
import { LoaderCircleIcon } from 'lucide-react'
import { useEffect, useState } from 'react'

interface SprintInfoType {
  startDate: Date
  endDate: Date
  name: string
  goal: string
}

const EXISTING_STATUS_VALUE = {
  inProgress: ['En cours', 'À corriger'].map((item) => item.toLowerCase()),
  toDo: ['À faire'].map((item) => item.toLowerCase()),
  testing: ['À tester Po', 'À tester Dev', 'À tester Client'].map((item) =>
    item.toLowerCase()
  ),
  done: ['Terminé', 'Déployé', 'À déployer'].map((item) => item.toLowerCase()),
}

const NO_SPRINT_GOAL_MSG = 'Sprint goal non renseigné'

export default function SprintInProgress() {
  const [sprintInfo, setSprintInfo] = useState<SprintInfoType>()
  const [isLoading, setIsLoading] = useState(true)
  const [ticketsTodo, setTicketsTodo] = useState<TicketType[]>([])
  const [ticketsInProgress, setTicketsInProgress] = useState<TicketType[]>([])
  const [ticketsTesting, setTicketsTesting] = useState<TicketType[]>([])
  const [ticketsDone, setTicketsDone] = useState<TicketType[]>([])
  const setSprintNameAtom = useSetAtom(SprintNameAtom)

  const splitTicketByStatus = (
    rawTicketsList: TicketType[],
    context: 'inProgress' | 'toDo' | 'testing' | 'done'
  ) => {
    rawTicketsList.forEach((item) => {
      const statusName = item.fields.status.name.toLowerCase()
      if (EXISTING_STATUS_VALUE[context].includes(statusName)) {
        switch (context) {
          case 'done':
            setTicketsDone((prev) => [...prev, item])
            break
          case 'inProgress':
            setTicketsInProgress((prev) => [...prev, item])
            break
          case 'testing':
            setTicketsTesting((prev) => [...prev, item])
            break
          case 'toDo':
            setTicketsTodo((prev) => [...prev, item])
            break
        }
      }
    })
  }

  const choosenTmpData = (
    dataInProgress: TicketType[],
    dataDone: TicketType[],
    dataTesting: TicketType[],
    dataToDo: TicketType[]
  ) => {
    if (dataInProgress.length) {
      return dataInProgress
    }
    if (dataDone.length) {
      return dataDone
    }
    if (dataTesting.length) {
      return dataTesting
    }
    if (dataToDo.length) {
      return dataToDo
    }
    return []
  }

  const getTicketList = async () => {
    try {
      const promises = [
        await getTicketInProgress(),
        await getTicketDone(),
        await getTicketTesting(),
        await getTicketToDo(),
      ]
      Promise.all(promises).then(
        ([
          { data: dataInProgress },
          { data: dataDone },
          { data: dataTesting },
          { data: dataToDo },
        ]: { data: TicketType[] }[]) => {
          /**
           * choose on ticket list by status to handle sprint info
           */
          const tmpData = choosenTmpData(
            dataInProgress,
            dataDone,
            dataTesting,
            dataToDo
          )
          if (tmpData.length) {
            const [firstTicket] = tmpData
            const _sprintInfo: SprintInfoType = {
              name: (
                firstTicket.fields.customfield_10020 as CustomField10020[]
              )[0].name,
              endDate: (
                firstTicket.fields.customfield_10020 as CustomField10020[]
              )[0].endDate,
              startDate: (
                firstTicket.fields.customfield_10020 as CustomField10020[]
              )[0].startDate,
              goal:
                (firstTicket.fields.customfield_10020 as CustomField10020[])[0]
                  .goal || NO_SPRINT_GOAL_MSG,
            }
            setSprintInfo(_sprintInfo)
            setSprintNameAtom(_sprintInfo.name)
            splitTicketByStatus(dataInProgress, 'inProgress')
            splitTicketByStatus(dataDone, 'done')
            splitTicketByStatus(dataTesting, 'testing')
            splitTicketByStatus(dataToDo, 'toDo')
          }
        }
      )
    } catch (error) {
      throw new Error('Error: ' + error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getTicketList()
  }, [])

  if (isLoading) {
    return (
      <div className="h-full w-full justify-center items-center flex">
        <LoaderCircleIcon className="animate-spin" width={'32'} height={'32'} />
      </div>
    )
  }
  return (
    <div className="text-primary-dark">
      <SprintInProgressHeader sprintInfo={sprintInfo!} />
      <FilterBar className='pt-6' />
      <SprintListing
        ticketsDone={ticketsDone}
        ticketsInProgress={ticketsInProgress}
        ticketsTesting={ticketsTesting}
        ticketsTodo={ticketsTodo}
      />
    </div>
  )
}
