import { useEffect, useState } from 'react'
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
  SortingState,
  getPaginationRowModel,
  getSortedRowModel,
} from '@tanstack/react-table'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Input } from '../ui/input'
import SearchIcon from '../icons/SearchIcon'
import { Button } from '../ui/button'
import ChevronLeft from '../icons/ChevronLeft'
import ChevronRight from '../icons/ChevronRight'
import FilterTabs from './FilterTabs'
import EmptyData from '../empty-data'
import { CheckCircle2 } from 'lucide-react'
import SortIcon from '../icons/SortIcon'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import FormFilter from './FormFilter'

type ErrorType = {
  search?: string
  description: {
    value: string
    file: string
    type: string
    substatus: 'new' | 'escalating' | 'ongoing'
    isUnhandled: boolean
    priority: string
    creationDate: string
    lastSeen: string
    id: string
  }
  occurences: number
  userCount: number
  action: string
  substatus: 'new' | 'escalating' | 'ongoing'
  isUnhandled: string
  priority: string
  creationDate: string
}
interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  errorList: ErrorType[]
  setCurrentExpendedRow: (prev: unknown) => string
}

export function DataTable<TData, TValue>({
  columns,
  data,
  errorList,
  setCurrentExpendedRow,
}: Readonly<DataTableProps<TData, TValue>>) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [formFilter, setFormFilter] = useState<{ dateFilter: string }>({
    dateFilter: 'asc',
  })
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
    },
  })

  const getPageNumbers = () => {
    const pageCount = table.getPageCount() // Total number of pages
    const pageIndex = table.getState().pagination.pageIndex + 1
    const totalPages = pageCount
    const delta = 2 // Number of pages around the current page

    const range = []
    for (
      let i = Math.max(2, pageIndex - delta);
      i <= Math.min(totalPages - 1, pageIndex + delta);
      i++
    ) {
      range.push(i)
    }

    if (pageIndex - delta > 2) {
      range.unshift('...')
    }
    if (pageIndex + delta < totalPages - 1) {
      range.push('...')
    }

    range.unshift(1) // Always show the first page
    if (totalPages > 1) {
      range.push(totalPages) // Always show the last page
    }

    return range
  }

  const handleFilterStatus = (filter: string) => {
    if (filter === 'Tous') {
      table.resetColumnFilters()
    } else if (filter === 'high') {
      table.resetColumnFilters()
      table.getColumn('priority')?.setFilterValue(filter)
    } else if (filter === 'isUnhandled') {
      table.resetColumnFilters()
      table.getColumn('isUnhandled')?.setFilterValue(filter)
    } else {
      table.resetColumnFilters()
      table.getColumn('substatus')?.setFilterValue(filter)
    }
  }

  useEffect(() => {
    table
      .getColumn('creationDate')
      ?.toggleSorting(table.getColumn('creationDate')?.getIsSorted() === 'asc')
  }, [formFilter.dateFilter])

  useEffect(() => {
    table.getColumn('search')?.toggleVisibility(false)
    table.getColumn('substatus')?.toggleVisibility(false)
    table.getColumn('priority')?.toggleVisibility(false)
    table.getColumn('isUnhandled')?.toggleVisibility(false)
    table.getColumn('creationDate')?.toggleVisibility(false)
  }, [])

  return (
    <div>
      <div className="flex pt-0 items-center justify-between border-b">
        <FilterTabs rawData={errorList} setFilterStatus={handleFilterStatus} />
        <div className="flex items-center gap-x-2">
          <DropdownMenu>
            <DropdownMenuTrigger className="-mt-2">
              <SortIcon className="[&_path]:stroke-primary-dark [&_path]:stroke-[]" />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-80">
              <DropdownMenuLabel>Filtrer par</DropdownMenuLabel>
              <FormFilter
                formFilter={formFilter}
                setFormFilter={setFormFilter}
              />
            </DropdownMenuContent>
          </DropdownMenu>
          <button className="-mt-2"></button>
          <Input
            placeholder="Rechercher par type d’erreur ou description"
            value={
              (table.getColumn('search')?.getFilterValue() as string) ?? ''
            }
            onChange={(event) =>
              table.getColumn('search')?.setFilterValue(event.target.value)
            }
            className="placeholder:text-[#717171] max-w-[275px] h-8 mb-2 placeholder:font-medium text-sm bg-white"
            prefixIcon={<SearchIcon width={20} height={20} className="-mt-2" />}
          />
        </div>
      </div>
      {!errorList.length ? (
        <div className="h-96">
          <EmptyData
            icon={
              <CheckCircle2
                width={40}
                height={40}
                className="text-success stroke-[1px]"
              />
            }
            msg="Aucune erreur détectée"
            bgIcon="bg-success/10"
          />
        </div>
      ) : (
        <div className="mt-5 border border-neutral-dark rounded-sm [&>div]:rounded-sm">
          <Table>
            <TableHeader className="bg-primary-dark [&_th]:text-xs [&_th]:p-4 [&_th]:text-light [&_tr]:hover:bg-primary-dark border border-primary-dark">
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>

            <TableBody className="bg-white [&_td]:p-3">
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && 'selected'}
                    className="hover:cursor-pointer"
                    onClick={() =>
                      setCurrentExpendedRow((prev: string) =>
                        prev === row.getValue('action')
                          ? ''
                          : row.getValue('action')
                      )
                    }
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    <div className="h-96">
                      <EmptyData
                        icon={
                          <CheckCircle2
                            width={40}
                            height={40}
                            className="text-success stroke-[1px]"
                          />
                        }
                        msg="Aucune erreur détectée"
                        bgIcon="bg-success/10"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      )}

      {/* Pagination Section */}
      {!!errorList.length && getPageNumbers().length > 1 && (
        <div className="flex items-center justify-end space-x-2 py-4">
          <Button
            variant="outline"
            className="w-8 h-8 p-0"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <ChevronLeft />
          </Button>

          {/* Page numbers */}
          {getPageNumbers().map((page, index) =>
            typeof page === 'string' ? (
              <span
                key={index + new Date().toLocaleDateString()}
                className="w-8 h-8 p-0 flex items-center justify-center"
              >
                {page}
              </span>
            ) : (
              <Button
                key={index + new Date().toLocaleDateString()}
                variant={
                  table.getState().pagination.pageIndex + 1 === page
                    ? 'default'
                    : 'outline'
                }
                className="w-8 h-8 p-0"
                onClick={() => table.setPageIndex(page - 1)}
              >
                {page}
              </Button>
            )
          )}

          <Button
            variant="outline"
            className="w-8 h-8 p-0"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <ChevronRight />
          </Button>
        </div>
      )}
    </div>
  )
}
