import CustomCard from '@/components/ui/custom-card'
import HeadingTitle from '@/components/ui/heading-title'
import DonutChart from '@/components/dashboard/DonutChart'
import TaskStatusList from '@/components/dashboard/TaskStatusList'
import ClockIcon from '@/components/icons/Clock'
import TaskListIcon from '@/components/icons/TaskList'
import TicketCard from '@/components/dashboard/TicketCard'
import SprintDuration from '@/components/dashboard/SprintDuration'
import UsefulLinks from '@/components/dashboard/UsefulLinks'
import { useState, useEffect, useRef } from 'react'
import { getDashboardData } from '@/services/api-client'
import { chartConfig } from '@/configs/chartConfig'
import Release from '@/components/dashboard/Release'
import { IDashboard, TeamMember } from '@/types/IDashboard'
import InterlocutorList from '@/components/dashboard/Interlocutor'
import CurrentNotifications from '@/components/dashboard/CurrentNotifications'
import ProfilLoader from '@/components/loader/profil-loader'
import VersionNoteLoader from '@/components/loader/version-note-loader'
import ArrowRight from '@/components/icons/ArrowRight'
import PerformanceItem from '@/components/dashboard/PerformanceItem'
import GaugeChart from '@/components/dashboard/GaudeChart'
import Coverage from '@/components/dashboard/Coverage'
import { isMonitoringAtom } from '@/atom/dashboard'
import { useAtom } from 'jotai'
import { isDisplayAllNotification } from '@/atom/notification'
import EmptyData from '@/components/empty-data'
import InformationIcon from '@/components/icons/Information'
import CircleRating from '@/components/code-quality/CircleRating'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import TabQualityItem from '@/components/dashboard/TabQualityItem'
import {
  getChartData,
  getMetricData,
  getRating,
  METRICS,
} from '@/lib/utils.quality'
import { QualityMetric } from '@/types/IQuality'
import { NavLink } from 'react-router-dom'
import UserFullLinkSection from '@/components/dashboard/Form/UserFullLink/UsefullLinkSection'
import useDirOwnerProdAccess from '@/hooks/use-dir-owner-prod-access'
interface IChartDataItem {
  key: string
  value: number
  fill: string
  name: string
}
interface ITaskItem {
  icon: JSX.Element
  count: number
  label: string
  name: string
}

interface MetricTab {
  id: string
  title: string
  style: string
  difference: string
  tooltip: string
  rating: string
  subtitle: string
  content: React.ReactNode
}

export default function Dashboard() {
  const [isMonitoring] = useAtom(isMonitoringAtom)
  const [_, setDisplayAllNotification] = useAtom(isDisplayAllNotification)
  const notifTriggerRef = useRef<HTMLElement>()
  const { hasAccess } = useDirOwnerProdAccess()

  const [dashboard, setDashboard] = useState<IDashboard | null>()
  const [isLoadDashboard, setIsLoadDashboard] = useState(true)
  const [chartData, setChartData] = useState<IChartDataItem[]>([
    { key: 'todo', value: 0, fill: '#1F73E0', name: 'todo_count' },
    {
      key: 'inProgress',
      value: 0,
      fill: '#1F73E099',
      name: 'in_progress_count',
    },
    { key: 'done', value: 0, fill: '#1F73E033', name: 'done_count' },
  ])
  const [taskList, setTaskList] = useState<ITaskItem[]>([
    {
      icon: <TaskListIcon />,
      count: 0,
      label: 'À faire',
      name: 'todo_count',
    },
    {
      icon: <ClockIcon />,
      count: 0,
      label: 'En cours',
      name: 'in_progress_count',
    },
    {
      icon: <ClockIcon />,
      count: 0,
      label: 'Terminées',
      name: 'done_count',
    },
  ])

  const qualityData = dashboard?.quality[0]?.quality ?? []

  const getQualityDifference = (metric: string) => {
    let stats,
      style,
      difference =
        getMetricData(qualityData as QualityMetric[], metric).current -
        getMetricData(qualityData as QualityMetric[], metric).last

    if (difference > 0) {
      stats = `+ ${difference}`
      style = 'bg-secondary/10 text-secondary'
    } else {
      stats = difference.toString().split('').join(' ')
      style = 'bg-success/10 text-success'
    }

    return {
      stats,
      style,
    }
  }

  const metrics: MetricTab[] = [
    {
      id: 'maintainability',
      title: 'Maintenabilité',
      tooltip:
        'Indicateur du nombre de code qui est confus où difficile à maintenir',
      rating: getRating(qualityData as QualityMetric[], METRICS.MAINTAINABILITY)
        .rating,
      style: getQualityDifference(METRICS.CODE_SMELLS).style,
      difference: getQualityDifference(METRICS.CODE_SMELLS).stats,
      subtitle: 'mauvaises pratiques',
      content: (
        <TabQualityItem
          data={getChartData(
            qualityData as QualityMetric[],
            METRICS.CODE_SMELLS
          )}
          legend="mauvaises pratiques"
        />
      ),
    },
    {
      id: 'reliability',
      title: 'Fiablilité',
      tooltip: "Indicateur du nombre d'erreur de codage",
      rating: getRating(qualityData as QualityMetric[], METRICS.RELIABILITY)
        .rating,
      style: getQualityDifference(METRICS.BUGS).style,
      difference: getQualityDifference(METRICS.BUGS).stats,
      subtitle: 'bugs',
      content: (
        <TabQualityItem
          data={getChartData(qualityData as QualityMetric[], METRICS.BUGS)}
          legend="bugs"
        />
      ),
    },
    {
      id: 'security',
      title: 'Sécurité',
      tooltip:
        'Indicateur du nombre de code pouvant être exploité par des pirates informatiques.',
      rating: getRating(qualityData as QualityMetric[], METRICS.SECURITY)
        .rating,
      style: getQualityDifference(METRICS.SECURITY_HOTSPOTS).style,
      difference: getQualityDifference(METRICS.SECURITY_HOTSPOTS).stats,
      subtitle: 'vulnérabilités',
      content: (
        <TabQualityItem
          data={getChartData(
            qualityData as QualityMetric[],
            METRICS.SECURITY_HOTSPOTS
          )}
          legend="vulnérabilités"
        />
      ),
    },
  ]

  useEffect(() => {
    fetchDashboardData()
  }, [])

  const fetchDashboardData = async () => {
    try {
      const { data } = await getDashboardData()
      setDashboard(data)

      setChartData((prev) =>
        prev.map((item) => ({ ...item, value: data[item.name] }))
      )

      setTaskList((prev) =>
        prev.map((item) => ({ ...item, count: data[item.name] }))
      )
    } catch (error) {
      throw new Error('Unexpected error occured')
    } finally {
      setIsLoadDashboard(false)
    }
  }

  const calculateAverageScore = (scores: number[]) => {
    return (
      Math.round(
        scores.reduce((sum, score) => sum + score, 0) / scores.length
      ) || 0
    )
  }

  const performanceMetrics = [
    { label: 'Meilleures pratiques', index: 0 },
    { label: 'Performance', index: 3 },
    { label: 'Vie privée', index: 6 },
  ]

  const getScores = () => {
    if (!dashboard?.scanners?.[0]?.last_advices) return []
    return performanceMetrics.map(
      (metric) => dashboard.scanners[0].last_advices[metric.index]?.score || 0
    )
  }

  const getCoverage = () => {
    if (!dashboard?.coverage.length) return []
    return dashboard.coverage.filter(
      (entry: any) => entry.coverage.length > 0
    )[0]?.coverage
  }
  const coverageData = getCoverage()

  const scores = getScores()
  const averageScore = calculateAverageScore(scores)

  return (
    <div className="h-full pb-6">
      <HeadingTitle label="Bonjour," />
      <div className="flex flex-col gap-4 h-[calc(100%-3.5rem)]">
        <div className="grid grid-cols-6 gap-4">
          {isMonitoring ? (
            <CustomCard title="Sprint actuel" className="col-span-4" tag={dashboard?.sprint_name}>
              {!taskList.every((item) => item.count === 0) ? (
                <div className="flex p-7 pt-0 gap-6">
                  <div className="grid grid-cols-2 items-center flex-1">
                    <div className="[&>div]:max-h-[210px] [&>div]:min-h-[170px]">
                      <DonutChart
                        data={chartData}
                        config={chartConfig}
                        label={{
                          title: dashboard?.tasks_count?.toString() ?? '0',
                          subtitle: 'Tâches',
                        }}
                      />
                    </div>
                    <TaskStatusList items={taskList} />
                  </div>
                  <div className="flex-none basis-[35%]">
                    <SprintDuration
                      className="mb-5"
                      startDate={dashboard?.sprint_start_date as string}
                      endDate={dashboard?.sprint_end_date as string}
                    />
                    <TicketCard
                      tickets={dashboard?.to_be_tested_count?.toString() ?? '0'}
                      label="Tickets à tester"
                    />
                  </div>
                </div>
              ) : (
                <div className="pt-8">
                  <EmptyData />
                </div>
              )}
            </CustomCard>
          ) : (
            <CustomCard
              title="Evolution de la qualité du code"
              className="col-span-4 [&_div]:mb-0"
            >
              {dashboard?.quality.length ? (
                <Tabs
                  defaultValue="maintainability"
                  orientation="vertical"
                  className="h-full flex flex-row-reverse"
                >
                  <TabsList className="rounded-none h-full w-1/3 flex flex-col border-l border-neutral-dark bg-white p-0">
                    {metrics.map((metric) => (
                      <TabsTrigger
                        key={metric.id}
                        value={metric.id}
                        className="border-b border-b-neutral-dark rounded-none w-full px-4 py-6 data-[state=active]:bg-neutral-light data-[state=active]:border-l-4  data-[state=active]:border-l-primary-light data-[state=active]:bg-primary-light/5 data-[state=active]:shadow-none"
                      >
                        <div className="w-full flex flex-col">
                          <div className="flex items-center justify-between pb-2">
                            <div className="inline-flex items-center gap-2">
                              <p className="text-primary-dark text-sm">
                                {metric.title}
                              </p>
                              <span className="[&_svg]:w-5 [&_svg]:h-5">
                                <InformationIcon />
                              </span>
                            </div>
                            <CircleRating
                              rating={metric.rating}
                              className="w-8 h-8"
                            />
                          </div>
                          {Number(metric.difference) !== 0 ? (
                            <div className="inline-flex items-center gap-1">
                              <p
                                className={`mr-1 text-xs py-1 px-2 rounded-full inline',
                                ${metric.style}
                              `}
                              >
                                {metric.difference}
                              </p>
                              <span className="text-left text-xs text-neutral-darker capitalize">
                                {metric.subtitle}
                              </span>
                            </div>
                          ) : (
                            <span className="text-left text-xs text-neutral-darker">
                              Aucune modifications récentes
                            </span>
                          )}
                        </div>
                      </TabsTrigger>
                    ))}
                  </TabsList>
                  {metrics.map((metric) => (
                    <TabsContent
                      key={metric.id}
                      value={metric.id}
                      className="mt-0 w-2/3"
                    >
                      {metric.content}
                    </TabsContent>
                  ))}
                </Tabs>
              ) : (
                <div className="flex items-center justify-center pt-8">
                  <EmptyData />
                </div>
              )}
            </CustomCard>
          )}
          <CustomCard
            title="Liens utiles"
            filter={hasAccess ? <UserFullLinkSection /> : undefined}
            className="col-span-2"
          >
            <div className="p-7 pt-0">
              {dashboard?.links.length ? (
                <UsefulLinks links={dashboard?.links} />
              ) : (
                <div className="pt-12">
                  <EmptyData />
                </div>
              )}
            </div>
          </CustomCard>
        </div>
        <div
          className="grid grid-cols-6 h-full gap-4
        "
        >
          {!isMonitoring && (
            <>
              <CustomCard title="Mes performances" className="col-span-2">
                {averageScore !== 0 ? (
                  <div className="px-8 flex flex-col gap-4 pb-6">
                    <GaugeChart value={averageScore} max={100} />
                    {performanceMetrics.map((metric, index) => (
                      <PerformanceItem
                        key={metric.label}
                        label={metric.label}
                        rating={scores[index]?.toString()}
                        id={
                          dashboard?.scanners?.[0]?.last_advices?.[metric.index]
                            ?.id ?? ''
                        }
                      />
                    ))}
                  </div>
                ) : (
                  <div className="p-8">
                    <EmptyData />
                  </div>
                )}
              </CustomCard>
              <CustomCard title="Couverture" className="col-span-2">
                {coverageData?.length ? (
                  <Coverage data={coverageData as any} />
                ) : (
                  <div className="pt-8">
                    <EmptyData />
                  </div>
                )}
              </CustomCard>
            </>
          )}
          {isMonitoring && (
            <>
              <CustomCard title="Mes interlocuteurs" className="col-span-2">
                {isLoadDashboard ? (
                  <div className="[&_svg]:mb-3 [&_svg]:w-full p-0">
                    <ProfilLoader />
                    <ProfilLoader />
                    <ProfilLoader />
                  </div>
                ) : (
                  <div className="pl-8 pr-0 pb-6">
                    <InterlocutorList
                      manager={dashboard?.project_manager as TeamMember}
                      devops={dashboard?.devops as TeamMember[]}
                      owners={dashboard?.product_owners as TeamMember[]}
                    />
                  </div>
                )}
              </CustomCard>
              <CustomCard
                title="Notifications récentes"
                className="col-span-2 relative"
              >
                <div className="w-full px-5 !pt-0">
                  <CurrentNotifications />
                </div>
                <button
                  ref={notifTriggerRef as any}
                  onClick={() =>
                    setDisplayAllNotification({
                      isShow: true,
                      triggerRef: notifTriggerRef,
                    })
                  }
                  className="absolute w-full bottom-0 flex py-3 hover:cursor-pointer hover:text-neutral-darker gap-x-4 text-sm text-neutral-darker/60 font-normal justify-center items-center"
                >
                  Voir toutes les notifications{' '}
                  <ArrowRight className="w-4 h-4" />
                </button>
              </CustomCard>
            </>
          )}

          <CustomCard title="Notes de version" className="col-span-2 relative">
            {isLoadDashboard ? (
              <div className="[&_svg]: px-8 pb-6 [&_svg]:w-full">
                <VersionNoteLoader />
                <VersionNoteLoader />
                <VersionNoteLoader />
              </div>
            ) : (
              <div className="px-8 pb-6 h-full">
                {dashboard?.releases.length ? (
                  <Release releases={dashboard?.releases} />
                ) : (
                  <div className="pt-8">
                    <EmptyData />
                    <NavLink
                      to={'/release'}
                      className="absolute right-1/2 w-full translate-x-1/2 bottom-0 flex py-3 hover:cursor-pointer hover:text-neutral-darker gap-x-4 text-sm text-neutral-darker/60 font-normal justify-center items-center"
                    >
                      Voir toutes les versions{' '}
                      <ArrowRight className="w-4 h-4" />
                    </NavLink>
                  </div>
                )}
              </div>
            )}
          </CustomCard>
        </div>
      </div>
    </div>
  )
}
