import { ReactNode } from 'react'
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card'
import clsx from 'clsx'

interface CustomCardProps {
  title: string
  children: ReactNode
  className?: string
  tag?: JSX.Element
  filter?: ReactNode
}

const CustomCard: React.FC<CustomCardProps> = ({
  title,
  children,
  className,
  tag,
  filter,
}) => {
  return (
    <Card
      className={clsx(
        'w-full border-neutral-dark rounded-md shadow-none border',
        className
      )}
    >
      <CardHeader className="px-8 py-6 border-b mb-6">
        <CardTitle className="text-xl font-semibold flex justify-between">
          <div className="inline-flex gap-3 items-center">
            <span> {title}</span>
            <span className="text-sm text-neutral-darker/60"> {tag}</span>
          </div>
          {filter}
        </CardTitle>
      </CardHeader>
      <CardContent className="p-0">{children}</CardContent>
    </Card>
  )
}

export default CustomCard
