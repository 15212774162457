import { useEffect, useState } from 'react'
import { Badge } from '../ui/badge'
import clsx from 'clsx'

const filterArr = [
  { value: 'new', label: 'Nouveau' },
  {
    value: 'isUnhandled',
    label: 'Non géré',
  },
  { value: 'high', label: 'Priorité haute' },
  {
    value: 'ongoing',
    label: 'En cours',
  },
]

const ErrorColor: Record<string, string> = {
  new: 'bg-yellow',
  high: 'bg-secondary',
  isUnhandled: 'bg-average',
  ongoing: 'bg-black',
  default: '',
}

type ErrorType = {
  search?: string
  description: {
    value: string
    file: string
    type: string
    substatus: 'new' | 'escalating' | 'ongoing'
    isUnhandled: boolean
    priority: string
    creationDate: string
    lastSeen: string
    id: string
  }
  occurences: number
  userCount: number
  action: string
}
export default function FilterTabs({
  rawData,
  setFilterStatus,
}: Readonly<{
  rawData: ErrorType[]
  setFilterStatus: (v: string) => void
}>) {
  const activeMenuClass =
    "after:content[' '] after:absolute after:w-full relative after:border-b-2 after:border-primary-light after:-bottom-3 after:left-0"

  const [activeMenu, setActiveMenu] = useState<string>('Tous')
  const [countOccurrences, setCountOccurrences] =
    useState<Record<string, number>>()
  const [total, setTotal] = useState(0)
  useEffect(() => {
    if (rawData) {
      const _countOccurrences_isUnhandled = rawData.reduce(
        (acc, num) => {
          if (num.description.isUnhandled === true) {
            acc['isUnhandled'] = (acc['isUnhandled'] || 0) + 1
          }
          return acc
        },
        {} as Record<string, number>
      )
      const _countOccurrences_priority = rawData.reduce(
        (acc, num) => {
          if (num.description.priority) {
            const priority = num.description.priority
            acc[priority] = (acc[priority] || 0) + 1
          }
          return acc
        },
        {} as Record<string, number>
      )
      const _countOccurrences_substatus = rawData.reduce(
        (acc, num) => {
          if (num.description.substatus) {
            const substatus = num.description.substatus
            acc[substatus] = (acc[substatus] || 0) + 1
          }
          return acc
        },
        {} as Record<string, number>
      )
      const _countOccurrences = {
        ..._countOccurrences_isUnhandled,
        ..._countOccurrences_priority,
        ..._countOccurrences_substatus,
      }
      setTotal(rawData.length)
      setCountOccurrences(_countOccurrences)
    }
  }, [rawData])

  useEffect(() => {
    setFilterStatus(activeMenu)
  }, [activeMenu])
  return (
    <ul className="text-sm [&>li]:cursor-pointer flex text-primary-dark gap-x-4 [&>li>span]:text-neutral-darker [&>li>span]:rounded-md [&>li>span]:py-1 [&>li>span]:px-2 [&>li>span]:bg-neutral-dark">
      <li className={activeMenu === 'Tous' ? activeMenuClass : ''}>
        <button
          className="flex items-center gap-x-1"
          onClick={() => setActiveMenu('Tous')}
        >
          Tous
          <Badge className="text-neutral-darker shadow-none rounded-md bg-neutral-darker/20 hover:cursor-pointer hover:bg-neutral-dark">
            {total}
          </Badge>
        </button>
      </li>
      {filterArr.map((item) => (
        <li
          key={item.value}
          className={activeMenu === item.value ? activeMenuClass : ''}
        >
          <button
            className="flex items-center gap-x-1"
            onClick={() => setActiveMenu(item.value)}
          >
            <div
              className={clsx(ErrorColor[item.value], 'w-2 h-2 rounded-[50%]')}
            />
            {item.label}{' '}
            {countOccurrences ? (
              <Badge className="text-neutral-darker shadow-none rounded-md bg-neutral-darker/20 hover:cursor-pointer hover:bg-neutral-dark">
                {countOccurrences[item.value] || 0}
              </Badge>
            ) : (
              ''
            )}
          </button>
        </li>
      ))}
    </ul>
  )
}
