import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Input } from '@/components/ui/input'
import { SearchIcon } from 'lucide-react'
import SortIcon from '@/components/icons/SortIcon'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import {
  filterFormAtom,
  filterStringAtom,
  ticketCountAtom,
  ticketEpicListAtom,
} from '@/atom/ticket-filter'
import { useEffect, useState } from 'react'
import { CrossCircledIcon } from '@radix-ui/react-icons'
import FilterBarForm from './FIlterBarForm'
import clsx from 'clsx'
import SprintSelector from '../sprint-past/SprintSelector'
import { ISprint } from '@/types/ISprintProgress'

const PRIORITY_BINDING: Record<string, string> = {
  Highest: 'Urgente',
  High: 'Forte',
  Medium: 'Moyenne',
  Low: 'Faible',
}

export default function FilterBar({
  className,
  infos,
  sprints,
  onSprintChange,
  searchPlaceholder = 'Rechercher par sujet ou clé',
}: Readonly<{
  className?: string
  handlePreviousSprint?: Function
  infos?: { filterKey: string; startDate: string; endDate: string }
  sprints?: ISprint[]
  onSprintChange?: (sprintKey: string) => void
  searchPlaceholder?: string
}>) {
  const ticketCount = useAtomValue(ticketCountAtom)
  const ticketEpicNameList = useAtomValue(ticketEpicListAtom)
  const setFilterString = useSetAtom(filterStringAtom)
  const [filterForm, setFilterForm] = useAtom(filterFormAtom)
  const [cleanedTicketCount, setCleanedTicketCount] = useState(0)
  const [ticketEpicNameListOptions, setTicketEpicNameListOptions] = useState<
    string[]
  >([])
  const [priorityResultFilter, setPriorityResultFilter] = useState<string[]>([
    ...filterForm.priority,
  ])
  const [epicNameResultFilter, setEpicNameResultFilter] = useState<string[]>([
    ...filterForm.epicName,
  ])
  const [isDisplayForm, setIsDisplayForm] = useState(false)

  const handleCheckbox = (v: string) => {
    if (priorityResultFilter.includes(v)) {
      setPriorityResultFilter((prev) => prev.filter((item) => item !== v))
    } else {
      setPriorityResultFilter((prev) => [...prev, v])
    }
  }

  const applyFilter = () => {
    setFilterForm((prev) => ({
      ...prev,
      epicName: [...epicNameResultFilter],
      priority: [...priorityResultFilter],
    }))
  }

  /**
   * CLEAR FILTER METHOD
   */
  const clearPriorityFilter = () => {
    setFilterForm((prev) => ({ ...prev, priority: [] }))
    setPriorityResultFilter([])
  }
  const clearEpicNameFilter = () => {
    setFilterForm((prev) => ({ ...prev, epicName: [] }))
    setEpicNameResultFilter([])
  }
  /** */

  useEffect(() => {
    if (typeof ticketCount === 'number') {
      setCleanedTicketCount(ticketCount)
    } else {
      setCleanedTicketCount(
        Object.values(ticketCount).reduce((acc, curr) => acc + curr, 0)
      )
    }
  }, [ticketCount])

  useEffect(() => {
    const cleanedArr: string[] = Array.from(
      new Set(Object.values(ticketEpicNameList).flat())
    )
    setTicketEpicNameListOptions(cleanedArr)
  }, [ticketEpicNameList])

  useEffect(() => {
    return () => {
      setFilterForm({ priority: [], epicName: [] })
    }
  }, [])

  return (
    <div className={clsx(className, 'w-full')}>
      <div className="py-2 flex items-center justify-between border-b">
        {infos ? (
          <div>
            <SprintSelector
              sprints={sprints!}
              currentSprint={infos}
              onSprintChange={onSprintChange!}
            />
          </div>
        ) : (
          <p className="text-sm">{cleanedTicketCount} tickets </p>
        )}
        <div className="flex items-center gap-x-2">
          <DropdownMenu
            open={isDisplayForm}
            onOpenChange={() => setIsDisplayForm(false)}
          >
            <DropdownMenuTrigger
              onClick={() => setIsDisplayForm(true)}
              className="-mt-2"
            >
              <SortIcon className="[&_path]:stroke-primary-dark [&_path]:stroke-[]" />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-80">
              <DropdownMenuLabel className="text-lg">
                Filtrer par
              </DropdownMenuLabel>
              {isDisplayForm ? (
                <FilterBarForm
                  applyFilter={applyFilter}
                  epicNameResultFilter={epicNameResultFilter}
                  handleCheckbox={handleCheckbox}
                  priorityResultFilter={priorityResultFilter}
                  setEpicNameResultFilter={setEpicNameResultFilter}
                  ticketEpicNameListOptions={ticketEpicNameListOptions}
                />
              ) : (
                ''
              )}
            </DropdownMenuContent>
          </DropdownMenu>
          <button className="-mt-2"></button>
          <Input
            onChange={(e) => setFilterString(e.target.value)}
            placeholder={searchPlaceholder}
            className="placeholder:text-[#717171] max-w-[275px] h-8 mb-2 placeholder:font-medium text-sm bg-white"
            prefixIcon={<SearchIcon width={20} height={20} className="-mt-2" />}
          />
        </div>
      </div>

      <div className="flex filter-result items-center gap-x-4 pt-6">
        {filterForm.priority.length ? (
          <div className="border bg-primary-light/10 flex items-center gap-x-2 border-primary-light rounded-full px-2 py-1 text-primary-light">
            Priorité:{' '}
            {filterForm.priority.map((item, index) =>
              index !== filterForm.priority.length - 1
                ? `${PRIORITY_BINDING[item]}, `
                : PRIORITY_BINDING[item]
            )}
            <button onClick={clearPriorityFilter}>
              <CrossCircledIcon className="w-5 h-5" />
            </button>
          </div>
        ) : (
          ''
        )}
        {filterForm.epicName.length ? (
          <div className="border bg-primary-light/10 flex items-center gap-x-2 border-primary-light rounded-full px-2 py-1 text-primary-light">
            EPIC:{' '}
            {filterForm.epicName.map((item, index) =>
              index !== filterForm.epicName.length - 1 ? `${item}, ` : item
            )}
            <button onClick={clearEpicNameFilter}>
              <CrossCircledIcon className="w-5 h-5" />
            </button>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
