import ColoredPlusIcon from '@/components/icons/ColoredPlusIcon'
import CrossIcon from '@/components/icons/CrossIcon'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { TOAST_ERROR_MSG, toastSuccessMsg } from '@/configs/constants'
import { Textarea } from '@/components/ui/textarea'
import { Separator } from '@/components/ui/separator'
import { useState } from 'react'
import { useToast } from '@/hooks/use-toast'
import CancelModalContent from '@/components/modal/cancel-modal-content'
import { createRelease } from '@/services/api-client'
import { IRelease } from '@/types/IRelease'

const formSchema = z.object({
  number: z
    .string()
    .min(1, {
      message: 'Le numbero de version ne peut pas être vide',
    })
    .max(20, {
      message: 'Le numbero de version doit pas depasser 20 caractères.',
    }),
  notes: z.string().min(1, {
    message: 'La description ne peut pas être vide',
  }),
})

export default function ReleaseForm({
  closeModal,
}: Readonly<{ closeModal: Function }>) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      number: '',
      notes: '',
    },
  })

  const { toast } = useToast()

  const [isLoading, setIsLoading] = useState(false)
  const [isOpenCancelCreaction, setIsOpenCancelCreaction] = useState(false)

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      setIsLoading(true)
      const { data }: { data: IRelease } = await createRelease(values)
      closeModal(data)
      toast(toastSuccessMsg('Note de version créée avec succès!'))
    } catch (error) {
      toast(TOAST_ERROR_MSG)
      throw new Error('Unexpected error occured')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <DialogDescription />
      <DialogTitle />
      <DialogClose className="absolute top-5 right-9 hover:cursor-pointer opacity-70 transition-opacity hover:opacity-100 data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
        <CrossIcon className="h-5 w-5" />
      </DialogClose>
      <div>
        <div className="flex justify-start items-center gap-x-2">
          <div className="p-1 rounded-md bg-primary-light/10 w-auto">
            <ColoredPlusIcon />
          </div>
          <span className="text-base font-medium text-primary-dark">
            Ajout d'une note de version
          </span>
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="pt-6 pb-8 space-y-8">
              <FormField
                control={form.control}
                name="number"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-primary-dark">Titre</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Ajoutez un numéro de version"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="notes"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-primary-dark">
                      Description
                    </FormLabel>
                    <FormControl>
                      <Textarea
                        {...field}
                        placeholder="Ajoutez les spécifications de la demande ..."
                        rows={12}
                      />
                    </FormControl>
                    <FormDescription>
                      Ajouter un # avant un texte si vous souhaitez le mettre en
                      gras (ex: #Gestion administrative)
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <Separator className="absolute  left-0 w-full" />
            <div className="flex justify-end items-center h-20 ">
              <Button
                variant={'ghost'}
                className="text-neutral-darker hover:bg-transparent"
                onClick={() => setIsOpenCancelCreaction(true)}
                type="button"
              >
                Annuler
              </Button>
              <Button type="submit" isLoading={isLoading}>
                Créer
              </Button>
            </div>
          </form>
        </Form>
      </div>

      {/* CONFIRMATION CLOSE DIALOG */}
      <Dialog
        open={!!isOpenCancelCreaction}
        onOpenChange={() => setIsOpenCancelCreaction(false)}
      >
        <DialogContent className="max-w-[480px]">
          <CancelModalContent onCancel={() => closeModal()} />
        </DialogContent>
      </Dialog>
    </div>
  )
}
