import InformationIcon from '@/components/icons/Information'
import PageAnalyzed from '@/components/performances/PageAnalyzed'
import PerformancesDesc from '@/components/performances/PerformancesDesc'
import MetricDetails from '@/components/performances/MetricDetails'
import AppAlert from '@/components/ui/app-alert'
import HeadingTitle from '@/components/ui/heading-title'
import { getPerformances } from '@/services/performances.service'
import { IPerformance } from '@/types/IPerformances'
import { useEffect, useState } from 'react'
import { LoaderCircleIcon } from 'lucide-react'
import MonitoringNoConfiguration from '@/components/monitoring-no-confiiguration'

export default function PerformancesPage() {
  const [pageList, setPageList] = useState<IPerformance[]>([])
  const [selectedPage, setSelectedPage] = useState<IPerformance>()
  const [isDisplayNotification, setIsDisplayNotification] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  const handleChangePage = (value: IPerformance) => {
    setSelectedPage(value)
  }

  async function fetchPeformancesDate() {
    try {
      const { data }: { data: IPerformance[] } = await getPerformances()
      if (data.length) {
        setPageList(data)
        setSelectedPage(data[0])
      }
    } catch (error) {
      throw new Error('Unexpected error occured')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchPeformancesDate()
  }, [])
  if (isLoading) {
    return (
      <div className="h-full w-full justify-center items-center flex">
        <LoaderCircleIcon className="animate-spin" width={'32'} height={'32'} />
      </div>
    )
  }
  if (!pageList?.length) {
    return (
      <MonitoringNoConfiguration
        context="Performances"
        urlImg="/img/no-config-performance.png"
        detailList={[
          'Surveiller la conformité avec les standards web',
          'Vérifier l’accessibilité de vos pages',
          'Être informé des performances de vos pages',
        ]}
      />
    )
  }
  return (
    <div>
      <HeadingTitle label="Performances" className="mb-0" />
      {isDisplayNotification ? (
        <AppAlert
          variant="info"
          handleClose={() => setIsDisplayNotification(false)}
          className="pl-2 rounded-md py-1"
        >
          <div className="flex items-center gap-x-4">
            <button className="rounded-md bg-primary-light/20 p-2">
              <InformationIcon className="[&_path]:stroke-primary-light" />
            </button>
            <p className="text-sm text-primary-dark">
              <span>
                Les résultats des analyses de performances sont actuellement
              </span>{' '}
              <span className="font-semibold">
                uniquement disponibles en Anglais
              </span>
            </p>
          </div>
        </AppAlert>
      ) : (
        ''
      )}
      <div className="grid grid-cols-3 gap-4 pt-6 pb-8">
        <PageAnalyzed
          handleChangePage={handleChangePage}
          selectedPage={selectedPage!}
          pageList={pageList}
        />
        <div className="bg-white col-span-2 rounded-md border">
          <p className="text-primary-dark font-semibold text-xl px-8 py-6 border-b">
            Page d'accueil
          </p>
          <PerformancesDesc selectedPage={selectedPage!} />
          <MetricDetails selectedPage={selectedPage!} />
        </div>
      </div>
    </div>
  )
}
