const RequestNotifIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 9H17"
        stroke="#D9306B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 12H17"
        stroke="#D9306B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 15H17"
        stroke="#D9306B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 9H7.01"
        stroke="#D9306B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 12H7.01"
        stroke="#D9306B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 15H7.01"
        stroke="#D9306B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 5H5C3.89543 5 3 6.04467 3 7.33333V16.6667C3 17.9553 3.89543 19 5 19H19C20.1046 19 21 17.9553 21 16.6667V7.33333C21 6.04467 20.1046 5 19 5Z"
        stroke="#D9306B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default RequestNotifIcon
