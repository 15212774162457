import { useEffect, useState } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { DataTable } from '@/components/vulnerability/DataTable'

import { getVulnerability } from '@/services/vulnerability.service'
import { ILastCheck, IVulnerability, IWeakness } from '@/types/IVulnerability'
import { Badge } from '@/components/ui/badge'
import RedirectIcon from '@/components/icons/RedirectIcon'
import clsx from 'clsx'
import { LoaderCircleIcon, ShieldAlertIcon } from 'lucide-react'
import RepositoryTabs from '@/components/ui/repository-tab'
import MonitoringNoConfiguration from '@/components/monitoring-no-confiiguration'
import HeadingTitle from '@/components/ui/heading-title'
import AppAlert from '@/components/ui/app-alert'
import InformationIcon from '@/components/icons/Information'

type VulnListType = {
  name: {
    summary: string
    severity: string
    package: string
  }
  weaknesses: IWeakness[]
  version: string[]
  search?: string
  severity?: string
}

export default function Vulnerability() {
  const columns: ColumnDef<VulnListType>[] = [
    {
      accessorKey: 'search',
      header: 'Nom et risque de la dépendance',
      cell: ({ row }) => {
        return (
          <div className="text-primary-dark text-sm font-semibold">
            {row.getValue('search')}
          </div>
        )
      },
    },
    {
      accessorKey: 'severity',
      header: 'Nom et risque de la dépendance',
      cell: ({ row }) => {
        return (
          <div className="text-primary-dark text-sm font-semibold">
            {row.getValue('severity')}
          </div>
        )
      },
    },
    {
      accessorKey: 'name',
      header: () => <p className="pl-1">Nom et risque de la dépendance</p>,
      cell: ({ row }) => {
        const {
          package: packgeValue,
          summary,
          severity,
        }: VulnListType['name'] = row.getValue('name')
        const severityColor: Record<string, string> = {
          critical: 'bg-black',
          high: 'bg-secondary',
          medium: 'bg-average',
          low: 'bg-success',
        }
        return (
          <div className="pl-2 text-primary-dark text-sm font-semibold relative">
            <span
              className={clsx(
                severityColor[severity.toLowerCase()],
                'absolute w-3 h-3 -left-3 top-1/2  -translate-y-1/2 rounded-e-full'
              )}
            />
            <p className="font-bold">{packgeValue}</p>
            <p className="font-normal flex items-start gap-1">
              <ShieldAlertIcon
                className="flex-[0_0_auto]"
                width={16}
                height={16}
              />{' '}
              {summary}
            </p>
          </div>
        )
      },
    },
    {
      accessorKey: 'weaknesses',
      header: 'Détails de la vulnérabilité',
      cell: ({ row }) => {
        const weaknesses: IWeakness[] = row.getValue('weaknesses')
        return (
          <div className="text-sm flex flex-col gap-y-2 w-auto font-semibold">
            {weaknesses.map((item, index) => (
              <Badge
                onClick={() =>
                  window.open(
                    `https://cwe.mitre.org/data/definitions/${item.cwe_id.split('CWE-')[1]}.html`,
                    'blank'
                  )
                }
                aria-hidden
                key={index + new Date().toISOString()}
                className="text-neutral-darker w-fit shadow-none py-1 rounded-full bg-neutral-dark hover:cursor-pointer hover:bg-neutral-dark"
              >
                <span className="underline flex items-center gap-x-2">
                  {item.cwe_id} <RedirectIcon className="-mt-1" />{' '}
                </span>
              </Badge>
            ))}
          </div>
        )
      },
    },
    {
      accessorKey: 'version',
      header: 'Version conseillée',
      cell: ({ row }) => {
        const [curr, fix]: string[] = row.getValue('version')
        return (
          <p className="text-primary-dark flex items-center gap-x-1 text-sm font-semibold">
            <span>{curr}</span> → <span>{fix}</span>
          </p>
        )
      },
    },
  ]
  const [vulnList, setVulnList] = useState<VulnListType[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [noApplication, setNoApplication] = useState(false)
  const [branches, setBranches] = useState<IVulnerability[]>([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [tabItems, setTabItems] = useState<
    Array<{ id: string; label: string }>
  >([])
  const [isDisplayInfo, setIsDisplayInfo] = useState(true)

  const handleTabChange = (index: number) => {
    setActiveIndex(index)
  }

  const formatData = () => {
    if (!branches?.[activeIndex]) {
      setVulnList([])
      return
    }

    const { last_check } = branches[activeIndex]
    if (!Array.isArray(last_check)) {
      setVulnList([])
      return
    }

    setTabItems(
      branches?.map((branch) => ({
        id: branch.repository.id,
        label: branch.repository.name,
      })) || []
    )

    const mappedResult: VulnListType[] = last_check.map((item: ILastCheck) => ({
      search: `${item.summary} ${item.severity}`,
      name: {
        summary: item.summary,
        severity: item.severity,
        package: item.package,
      },
      weaknesses: item.weaknesses || [],
      version: [item.current_version, item.fix_version],
      severity: item.severity,
    }))
    setVulnList(mappedResult)
  }

  async function fetchData() {
    try {
      const { data }: { data: IVulnerability[] } = await getVulnerability()
      if (!data?.length) {
        setNoApplication(true)
        return
      }
      setBranches(data)
    } catch (error) {
      throw new Error('Unexpected error occured')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (branches) {
      formatData()
    }
  }, [branches, activeIndex])

  if (isLoading) {
    return (
      <div className="h-full flex items-center w-full justify-center">
        <LoaderCircleIcon className="animate-spin w-8 h-8" />
      </div>
    )
  }

  return (
    <>
      <HeadingTitle label="Vulnérabilités" className="mb-0" />
      {isDisplayInfo ? (
        <AppAlert
          className="rounded-md !p-2 mb-6"
          variant="info"
          handleClose={() => setIsDisplayInfo(false)}
        >
          <div className="flex items-center gap-x-4">
            <button className="rounded-md bg-primary-light/20 p-2">
              <InformationIcon className="[&_path]:stroke-primary-light" />
            </button>
            <p className="text-sm text-primary-dark font-normal">
              Les résultats des analyses de performances sont actuellement{' '}
              <span className="font-semibold">
                disponible uniquement en Anglais
              </span>
            </p>
          </div>
        </AppAlert>
      ) : (
        ''
      )}
      {noApplication ? (
        <MonitoringNoConfiguration
          context="Vulnérabilités"
          detailList={[
            'Être informé des dépendances à mettre à jour',
            'Connaître les vulnérabilités critiques à résoudre',
          ]}
          urlImg="/img/no-vuln.png"
        />
      ) : (
        <>
          {branches.length > 0 && (
            <RepositoryTabs
              items={tabItems}
              onChange={handleTabChange}
              activeIndex={activeIndex}
            />
          )}
          <div className="bg-white p-6 border border-neutral-dark">
            <DataTable columns={columns} vulnList={vulnList} data={vulnList} />
          </div>
        </>
      )}
    </>
  )
}
